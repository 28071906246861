import React from "react"
import Figure from "./figure"
import SectionHeader from "./sectionHeader"
import Buttons from "./buttons"

import styles from "./cta.module.scss"

const CTA = (props) => {
  return (
    <>
      {(props.title || props.body || (props.image && props.image.asset) || (props.links && props.links.length > 0)) &&
        <section className={styles.cta} data-background={props.background} data-style={props.style}>
          <div className="grid">
            <div className="gridItem">
              <div className={props.image ? styles.ctaInnerAlt : styles.ctaInner}>
                {props.image && props.image.asset &&
                  <div className={styles.ctaImage}>
                    <Figure {...props.image} />
                  </div>
                }
                <div className={styles.ctaBody}>
                  <SectionHeader
                    title={props.title}
                    content={props.body}
                    align={props.image ? `left` : `center`}
                  />
                  {props.links && <Buttons buttons={props.links} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default CTA

